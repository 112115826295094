import '../../images/form-image.png'

const forms = document.querySelectorAll('.form__content, .formWidget__form')
if(forms.length > 0) {
    forms.forEach(form => {
        form.addEventListener('submit', e => {
            e.preventDefault()

            document.querySelectorAll('.hasError').forEach(el => el.classList.remove('hasError'))

            let hasErorrs = false
            const inputs = form.querySelectorAll('.form__input input')
            inputs.forEach(input => {
                if(Number(input.dataset.required) === 1 && input.value === '') {
                    input.parentNode.classList.add('hasError')
                    hasErorrs = true
                }
            })

            if(hasErorrs) return

            let ymTarget = form.dataset.ymtarget

            const action = form.getAttribute("action");
            if(!action) return

            let facts = new FormData(form);
            fetch(action, {
                method : "POST",
                body: facts,
                headers: {
                    "X-Requested-With":"XMLHttpRequest"
                }
            }).then(response => {
                let answer = document.createElement('div')
                answer.classList.add('form__content')
                answer.innerHTML = `<div class="form__heading">Спасибо ваша заявка отправлена</div>
                <img src="/images/form-image.png" alt="" />`
                form.parentNode.prepend(answer)
                if(form.classList.contains('formWidget__form')){
                    form.parentNode.append(answer)
                    form.parentNode.querySelector('.formWidget__text').remove()
                } else {
                    form.parentNode.prepend(answer)
                }
                form.remove()

                if(ymTarget && ym) {
                    //ym(87824935,'reachGoal',ymTarget)
                }
            })
        })
    })
}

document.querySelectorAll('[type="tel"]').forEach(el => {
    el.addEventListener('input', e => {
        let r = '+'
        let phone = e.target.value.replace(/\D/g, "")
        if(phone === '') return e.target.value = '+7'

        let prefix = phone.charAt(0)
        if(prefix !== '7') prefix = '7'
        phone = phone.substring(1)
        r += `${prefix}`
        if(phone.length > 0) r += ` ${phone.substring(0, 3)}`
        if(phone.length > 3) r += ` ${phone.substring(3, 6)}`
        if(phone.length > 6) r += `-${phone.substring(6, 8)}`
        if(phone.length > 8) r += `-${phone.substring(8, 10)}`

        return e.target.value = r
    })
})