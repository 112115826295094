import '../../images/restricted.png'

const modals = document.querySelector('.modals')
if(modals) {
    const openModal = (id, closable = true) => {
        const modal = modals.querySelector(`[data-modal=${id}]`)
        if(modal) {
            modal.parentNode.classList.add('opened')
            modal.classList.add('opened')
            const modalClose = modal.querySelector('.modal__close')
            if(modalClose) {
                modalClose.addEventListener('click', () => {
                    closeAllModals()
                })
            }

            if(closable) {
                modals.addEventListener('click', e => {
                    if(e.target === modals) {
                        closeAllModals()
                    }
                })
            }
        }
    }

    const closeAllModals = () => {
        modals.classList.remove('opened')
        modals.querySelectorAll('[data-modal]').forEach(el => el.classList.remove('opened'))
    }

    const modalsButtons = document.querySelectorAll('[data-call-modal]')
    if(modalsButtons.length > 0) {
        modalsButtons.forEach(button => {
            button.addEventListener('click', e => {
                e.preventDefault()
                const modalID = button.dataset?.callModal ?? null
                if(modalID) {
                    openModal(modalID)
                }
            })
        })
    }

    const getCookie = (key) => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${key}=`)
        if(parts.length === 2){
            return parts.pop().split(';').shift()
        } else return null
    }

    let ageCheck = getCookie('mldev_age_check')
    if(ageCheck !== 'passed') {
        openModal('ageCheck', false)
    }

    const confirmButton = document.getElementById('confirmButton')
    if(confirmButton) {
        confirmButton.addEventListener('click', () => {
            const date = new Date()
            date.setTime(date.getTime() + (525600*60*1000))
            document.cookie = `mldev_age_check=passed;expires=${date.toUTCString()};path=/`
            closeAllModals()
        })
    }

    const rejectButton = document.getElementById('rejectButton')
    if(rejectButton) {
        rejectButton.addEventListener('click', () => {
            document.body.innerHTML = `<div class="restricted">
                <div class="restricted__bg">
                    <img src="/images/restricted.png" alt="" />
                </div>
        
                <div class="restricted__text">Простите, сайт доступен только лицам<br><span>достигшим 18 лет</span></div>
            </div>`
        })
    }
}